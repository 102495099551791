<template>
    <div class="coupons">
        <h2>{{$t('couponsPageTitle')}}</h2>
        <div class="fuel-loyalty">
             <div class="loyalty">
                <div class="loyalty-text">
                    <span class="car-name">{{$tc('loyaltyPageText', loyaltyPoints, {loyaltyPoints : loyaltyPoints})}}.</span>
                </div>
            </div>
        </div>
        <div v-for="(coupon, index) in coupons" :key="index" class="coupon">
            <img v-bind:src="coupon.image" />
            <div class="coupon-text">
                <span class="coupon-title">{{coupon.title}}</span>
                <span v-if="(loyaltyPoints < coupon.loyaltyPoints || coupon.loyaltyPoints > 30)" class="coupon-loyalty-needed">{{$t('couponLoyaltyPointsNeeded', {loyaltyPoints : coupon.loyaltyPoints})}}</span>
                <span v-else class="coupon-loyalty-needed">{{$t('couponLoyaltyPoints', {loyaltyPoints : coupon.loyaltyPoints, availableLoyalty: loyaltyPoints})}}</span>
            </div>
            <div class="coupon-redeem">
                <img @click="goToScanner(coupon)" :class="{'disabled' : (loyaltyPoints < coupon.loyaltyPoints || coupon.loyaltyPoints > 30) }" src="@/assets/img/redeem.png" />
            </div>
        </div>
        <span v-if="coupons.length == 0">{{$t('noCoupons')}}</span>
    </div>
</template>
<script>
    var Stats = require("@/assets/js/Stats");

    export default{
        name:"coupons",
        data(){
            return {
                qrCouponCode : null,
                coupons:[
                    {
                        title: this.$t("coffee"),
                        loyaltyPoints: 12,
                        image: require("@/assets/img/coffee.png")
                    },
                    {
                        title: this.$t("chocolateBar"),
                        loyaltyPoints: 40,
                        image: require("@/assets/img/energy-bar.png")
                    },
                    {
                        title: this.$t("sandwich"),
                        loyaltyPoints: 100,
                        image: require("@/assets/img/sandwich.png")
                    }
                ],
                loyaltyPoints : localStorage.getItem("DKC_fuel_loyalty") || 0
            }
        },
        methods:{
            goToScanner(coupon){
                if(this.loyaltyPoints >= coupon.loyaltyPoints && coupon.loyaltyPoints < 30){
                    this.$parent.loader.showLoader = true;
                    this.$parent.loader.content = this.$t("loadingText");
                    setTimeout(()=>{
                        this.$router.push(this.$parent.base + "/scan?scanCoupon=true");
                    }, 1000);
                }
            }
        },
        mounted(){
            Stats.setStats({ nbCouponsAccess : 1 })
        }
    }
</script>

<style scoped>
    
</style>
